import React, { useCallback, useEffect, useState, useRef, FC } from 'react';
import PropTypes from 'prop-types';
import { useAppSelector } from '../../../../hooks/redux';

import '../../multiRangeSlider.css';

interface MultiRangeSliderPriceProps {
  min: number;
  max: number;
  onChange: ({ min, max }: { min: number; max: number }) => void;
}

export const MultiRangeSliderPrice: FC<MultiRangeSliderPriceProps> = ({ min, max, onChange }) => {
  const { initPrice } = useAppSelector(state => state.filters);

  const minRef = useRef(min);
  const maxRef = useRef(max);
  const range = useRef<HTMLDivElement>(null);
  const rangeMax = useRef<any>(max);
  const rangeMin = useRef<any>(min);
  const [minLimit] = useState(initPrice.min);
  const [maxLimit] = useState(initPrice.max);

  // Преобразую в проценты
  const getPercent = useCallback(
    (value: number) => Math.round(((value - minLimit) / (maxLimit - minLimit)) * 100),
    [min, max],
  );

  const minPercent = getPercent(min);
  const maxPercent = getPercent(max);

  // Устанавливаю ширину диапазона для уменьшения слева
  useEffect(() => {
    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
      rangeMin.current.style.left = `${minPercent * 0.91}%`;
    }
  }, [min, getPercent]);

  // Устанавливаю ширину диапазона для уменьшения с правой стороны
  useEffect(() => {
    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
      rangeMax.current.style.left = `${maxPercent - maxPercent * 0.11}%`;
    }
  }, [max, getPercent]);

  // Получаю минимальное и максимальное значения при изменении их состояния
  return (
    <div className="multi-slider">
      <input
        type="range"
        min={minLimit}
        max={maxLimit}
        value={min}
        onChange={event => {
          const value = Math.min(Number(event.target.value), max - 1);
          minRef.current = value;
          onChange({ min: value, max });
        }}
        className="thumb thumb--left"
        {...(min > max - 100 && { style: { zIndex: 5 } })}
      />
      <input
        type="range"
        min={minLimit}
        max={maxLimit}
        value={max}
        onChange={event => {
          const value = Math.max(Number(event.target.value), min + 1);
          maxRef.current = value;
          onChange({ min: min, max: value });
        }}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div ref={rangeMin} className="slider__left-value">
          {min}
        </div>
        <div ref={rangeMax} className="slider__right-value">
          {max}
        </div>
      </div>
    </div>
  );
};

MultiRangeSliderPrice.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
