import { FC, useMemo } from 'react';
import { ShopTypes } from '../../../models/IShop';
import { getShopTypeName } from '../../../utils';

import './styles.css';

interface ResetBasketModalProps {
  shopTypeId: ShopTypes;
  handleClose: () => void;
  handleAccept: () => void;
}

export const ResetBasketModal: FC<ResetBasketModalProps> = ({
  shopTypeId,
  handleClose,
  handleAccept,
}) => {
  const shopName = useMemo(() => getShopTypeName(shopTypeId), [shopTypeId]);

  return (
    <>
      <div className={'reset-basket-modal__head'}>
        <div
          className={'reset-basket-modal__text'}
        >{`Для создания нового списка для ${shopName} нужно очистить предыдущий\n\nХотите очистить список?`}</div>
        <div className={'reset-basket-modal__close-icon'} onClick={handleClose} />
      </div>
      <div className={'reset-basket-modal__gray-line'} />
      <div className={'reset-basket-modal__buttons'}>
        <div
          className={'reset-basket-modal__button reset-basket-modal__button-red'}
          onClick={handleAccept}
        >
          ДА
        </div>
        <div
          className={'reset-basket-modal__button reset-basket-modal__button-white'}
          onClick={handleClose}
        >
          НЕТ
        </div>
      </div>
    </>
  );
};
