import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterState, Interval } from './types';
import { fetchShopFilters } from './actions';
import { IShopFilter } from '../../models/IShopFilter';

export const initialState: FilterState = {
  initSale: {} as Interval,
  tempSale: {} as Interval,
  initPrice: {} as Interval,
  tempPrice: {} as Interval,
  sort: {
    type: 'name',
    order: 'asc',
  },
  searchString: '',
  isLoadingFilters: false,
  error: '',
};

const filtersSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<FilterState>) => {
      state.tempPrice = action.payload.tempPrice;
      state.tempSale = action.payload.tempSale;
      state.sort = action.payload.sort;
    },
    setSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    resetFilter: state => {
      state.tempPrice = state.initPrice;
      state.tempSale = state.initSale;
      state.sort = initialState.sort;
    },
  },
  extraReducers: {
    [fetchShopFilters.pending.type]: state => {
      state.isLoadingFilters = true;
    },
    [fetchShopFilters.fulfilled.type]: (state, action: PayloadAction<IShopFilter>) => {
      const { priceMin, priceMax, discountMin, discountMax } = action.payload;
      state.isLoadingFilters = false;
      state.error = '';
      state.initSale = {
        min: discountMin,
        max: discountMax,
      };
      state.tempSale = {
        min: discountMin,
        max: discountMax,
      };
      state.initPrice = {
        min: Math.trunc(priceMin),
        max: Math.trunc(priceMax) + 1,
      };
      state.tempPrice = {
        min: Math.trunc(priceMin),
        max: Math.trunc(priceMax) + 1,
      };
    },
    [fetchShopFilters.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingFilters = false;
      state.error = action.payload;
    },
  },
});

export const { setFilters, setSearchString, resetFilter } = filtersSlice.actions;

const reducer = filtersSlice.reducer;
export default reducer;
