import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import './styles.css';

export const MoveTopButton: FC = () => {
  const { pathname } = useLocation();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const checkScroll = (e: Event) => {
    const element = e?.target as HTMLDivElement;
    const coords = document.documentElement.clientHeight;

    if (element.scrollTop > coords) {
      setIsVisible(true);
    }
    if (element.scrollTop < coords) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    setIsVisible(false);
    const content = document?.querySelector('.wrapperMain') as HTMLDivElement;
    if (content) {
      content?.addEventListener('scroll', checkScroll);
    }
    return () => {
      document.removeEventListener('scroll', checkScroll);
    };
  }, [pathname]);

  const handleClick = () => {
    const content = document?.querySelector('.wrapperMain') as HTMLDivElement;
    if (content) {
      content.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={`move-top${isVisible ? '' : ' move-top__none'}`} onClick={handleClick}>
      <div className="move-top__image" />
    </div>
  );
};
