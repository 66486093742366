import React, { FC, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTotals } from '../../hooks/useTotals';

import './styles.css';

export const Header: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { totalProductsCount } = useTotals();
  const isBasket = useMemo(() => pathname === '/basket', [pathname]);
  const [isShadowActive, setIsShadowActive] = useState<boolean>(false);

  const checkScroll = (e: Event) => {
    const element = e?.target as HTMLDivElement;
    if (element?.scrollTop) {
      setIsShadowActive(true);
    } else {
      setIsShadowActive(false);
    }
  };

  useEffect(() => {
    setIsShadowActive(false);
    const content = document?.querySelector('.wrapperMain') as HTMLDivElement;
    if (content) {
      content?.addEventListener('scroll', checkScroll);
    }
    return () => {
      document.removeEventListener('scroll', checkScroll);
    };
  }, [pathname]);

  const handleClick = () => {
    navigate(isBasket ? '/catalog' : '/basket');
  };

  return (
    <div className={`header${isShadowActive ? ' header__shadow' : ''}`}>
      <div className={'header__back'} onClick={() => navigate('/')}>
        ←
      </div>
      <Link style={{ textDecoration: 'none' }} to="/">
        <div className="header__magnit-icon" />
      </Link>
      <div className="header__title">{isBasket ? 'План покупок' : 'Скидки'}</div>

      {isBasket && <div className="header__close-icon" onClick={handleClick} />}
      {!isBasket && (
        <div className="header__basket-button" onClick={handleClick}>
          <div className="header__basket-icon" />
          <div className="header__basket-count">{totalProductsCount}</div>
        </div>
      )}
    </div>
  );
};
