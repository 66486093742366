import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProductsReq, getProductsType } from '../../API';

export const fetchProducts = createAsyncThunk(
  'products/fetchAll',
  async (params: getProductsType, thunkAPI) => {
    try {
      return await getProductsReq(params);
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить товары');
    }
  },
);
