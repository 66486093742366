import { useAppSelector } from './redux';
import { ShopTypes } from '../models/IShop';

export type Totals = {
  [key in ShopTypes]: {
    totalShopPrice: string;
    totalShopBenefit: string;
  };
};

export const useTotals = () => {
  const {
    basket: { basketProducts },
    shops: { currentShop },
  } = useAppSelector(state => state);
  let totalPrice = 0;
  let totalBenefit = 0;

  const totals = {} as Totals;

  const totalProductsCount = basketProducts?.[currentShop.typeId]?.length || 0;

  for (const key in basketProducts) {
    const curProducts = basketProducts[Number(key) as ShopTypes];

    const price = {
      totalNonZeroPrice: 0,
      totalZeroPrice: 0,
      totalOldPrice: 0,
    };

    curProducts.forEach(product => {
      if (product.oldPrice) {
        price.totalNonZeroPrice += product.amount * product.price;
      } else {
        price.totalZeroPrice += product.amount * product.price;
      }
      price.totalOldPrice += product.oldPrice ? product.amount * product.oldPrice : 0;
    });

    totalPrice += price.totalNonZeroPrice + price.totalZeroPrice;
    totalBenefit += price.totalOldPrice - price.totalNonZeroPrice;

    totals[Number(key) as ShopTypes] = {
      totalShopPrice: (price.totalNonZeroPrice + price.totalZeroPrice).toFixed(2),
      totalShopBenefit: (price.totalOldPrice - price.totalNonZeroPrice).toFixed(2),
    };
  }

  return {
    totalProductsCount,
    totalPrice: totalPrice.toFixed(2),
    totalBenefit: totalBenefit.toFixed(2),
    totals,
  };
};
