import React, { FC } from 'react';
import { WrapperContent } from '../../components/WrapperContent';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { Wrapper } from '../../components/Wrapper';
import { BasketShop } from './components/BasketShop';
import { ShopTypes } from '../../models/IShop';
import { useTotals } from '../../hooks/useTotals';
import { postBasketIsSend, sendBasketToChat } from '../../store/basket/actions';
import { useSelectedShops } from '../../hooks/useSelectedShops';
import { generateSendBasketReqData, numberWithSpaces } from '../../utils';

import './styles.css';

export const BasketList: FC = () => {
  const { basketProducts } = useAppSelector(state => state.basket);
  const { selectedShopsFromLocal } = useSelectedShops();
  const { totalPrice, totals } = useTotals();
  const dispatch = useAppDispatch();

  const handleClick = async () => {
    if (!selectedShopsFromLocal) {
      return;
    }

    for (const key in selectedShopsFromLocal) {
      const shop = selectedShopsFromLocal[Number(key) as ShopTypes];
      const products = basketProducts[shop.typeId];

      if (products?.length) {
        await dispatch(postBasketIsSend({ products, shopId: shop.apiId }));
      }
    }

    const resData = generateSendBasketReqData(selectedShopsFromLocal, basketProducts, totals);
    await dispatch(sendBasketToChat(resData));
  };

  return (
    <Wrapper>
      <WrapperContent
        noPadding
        underWrapper={
          <div className={'basket__under-content'}>
            <div className={'basket__total-price'}>
              <p>Всего:</p>
              <p>{numberWithSpaces(totalPrice)}₽</p>
            </div>
            <div className={'basket__send-button'} onClick={handleClick}>
              Отправить
            </div>
            <div className={'basket__send-text'}>
              Отправить в чат-бот сформированный список покупок
            </div>
          </div>
        }
      >
        {Object.keys(basketProducts).map((key, i) => (
          <BasketShop key={`basket-shop-${i}`} shopType={Number(key) as ShopTypes} />
        ))}
      </WrapperContent>
    </Wrapper>
  );
};
