import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTelegram } from '../../hooks/useTelegram';
import { delay, randomInteger } from '../../utils';
import { BASE_URL_SEND_FIRST, BASE_URL_SEND_SECOND, isDevApi } from '../../config';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setAnimation } from '../../store/shops';

import './styles.css';

export const MainMenu: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { animationOff } = useAppSelector(state => state.shops);
  const { chatId, queryId, onClose, onOpenLink } = useTelegram();

  const handleClose = async () => {
    await delay(1000);
    dispatch(setAnimation(true));
    onClose();
  };

  const mainButtons = [
    {
      title: 'КАТАЛОГ СКИДОК',
      icon: 'main-menu__bonus-icon',
      onClick: () => {
        dispatch(setAnimation(true));
        navigate('/shops');
      },
    },
    {
      title: 'РЕЦЕПТЫ',
      icon: 'main-menu__dish-icon',
      onClick: async () => {
        await axios.post(
          `${BASE_URL_SEND_FIRST}/Members/ExecuteCommand?command=/sreceipt&query_id=${queryId}`,
        );
        if (!isDevApi) {
          await axios.post(
            `${BASE_URL_SEND_SECOND}/Members/ExecuteCommand?command=/sreceipt&query_id=${queryId}`,
          );
        }
        await handleClose();
      },
    },
    {
      title: 'ПОМОЩЬ',
      icon: 'main-menu__question-icon',
      onClick: async () => {
        await axios.post(
          `${BASE_URL_SEND_FIRST}/Members/ExecuteCommand?command=/help&query_id=${queryId}`,
        );
        if (!isDevApi) {
          await axios.post(
            `${BASE_URL_SEND_SECOND}/Members/ExecuteCommand?command=/help&query_id=${queryId}`,
          );
        }
        await handleClose();
      },
    },
    {
      title: 'ПОДЕЛИТЬСЯ БОТОМ',
      icon: 'main-menu__share-icon',
      onClick: async () => {
        await axios.post(
          `${BASE_URL_SEND_FIRST}/Members/ExecuteCommand?command=/sharebot&query_id=${queryId}`,
        );
        if (!isDevApi) {
          await axios.post(
            `${BASE_URL_SEND_SECOND}/Members/ExecuteCommand?command=/sharebot&query_id=${queryId}`,
          );
        }
        await handleClose();
      },
    },
    {
      title: 'ПЕРЕЗАПУСТИТЬ БОТА',
      icon: 'main-menu__refresh-icon',
      onClick: async () => {
        await axios.post(
          `${BASE_URL_SEND_FIRST}/Members/ExecuteCommand?command=/start&query_id=${queryId}`,
        );
        if (!isDevApi) {
          await axios.post(
            `${BASE_URL_SEND_SECOND}/Members/ExecuteCommand?command=/start&query_id=${queryId}`,
          );
        }
        await handleClose();
      },
    },
  ];

  const bottomButtons = [
    {
      title: 'Отправить\nчек',
      icon: 'main-menu__check-icon',
      onClick: async () => {
        await axios.post(
          `https://api.telegram.org/${
            'bot1738951282:AAGRMPBbk64tFW5ZGJzCSZbrdFNti3gAydU'
            // isDevApi
            //   ? 'bot1738951282:AAGRMPBbk64tFW5ZGJzCSZbrdFNti3gAydU'
            //   : 'bot1516450805:AAHiZv0tcRPry302BblVx3pTu3GQOSkirtg'
          }/sendMessage?chat_id=${chatId}&text=Отправьте новый чек 🧾`,
        );
        await handleClose();
      },
    },
    {
      title: 'Условия\nакции',
      icon: 'main-menu__info-icon',
      onClick: () => {
        navigate('/terms');
        dispatch(setAnimation(true));
      },
    },
    {
      title: 'Личный\nкабинет',
      icon: 'main-menu__human-icon',
      onClick: async () => {
        await axios.post(
          `${BASE_URL_SEND_FIRST}/Members/ExecuteCommand?command=/cabinet&query_id=${queryId}`,
        );
        if (!isDevApi) {
          await axios.post(
            `${BASE_URL_SEND_SECOND}/Members/ExecuteCommand?command=/cabinet&query_id=${queryId}`,
          );
        }

        await handleClose();
      },
    },
    {
      title: 'Скачать\nприложение',
      icon: 'main-menu__phone-icon',
      onClick: async () => {
        onOpenLink('https://redirect.appmetrica.yandex.com/serve/1108706291364151573');
        dispatch(setAnimation(true));
      },
    },
  ];

  const opacity = ['zoom-in-1', 'zoom-in-2', 'zoom-in-3', 'zoom-in-4'];

  const getPosition = () => {
    let left = randomInteger(0, 100);
    let top = randomInteger(0, 40);

    while (left > 35 && left < 65 && top > 1 && top < 15) {
      left = randomInteger(0, 100);
      top = randomInteger(0, 40);
    }

    return { left, top };
  };

  return (
    <div className={'main-menu'}>
      {Array.from({ length: 20 }).map((_, i) => {
        const pos = getPosition();
        return (
          <div
            key={`snow-${i}`}
            className={
              (i < 4 ? 'main-menu__star-icon' : 'main-menu__circle-icon') +
              ' main-menu__snow ' +
              opacity[randomInteger(0, 3)]
            }
            style={{
              left: `${pos.left}%`,
              top: `${pos.top}%`,
              width: i < 4 ? `${randomInteger(20, 35)}px` : `${randomInteger(4, 10)}px`,
              animationDelay: animationOff
                ? `${randomInteger(0, 10) / 10}s`
                : `${7.4 + randomInteger(10, 20) / 10}s`,
              animationDuration: `0.${randomInteger(20, 30) / 10}s`,
            }}
          />
        );
      })}
      <div className={'main-menu__wrapper'}>
        <div
          className={`main-menu__magnit-icon ${animationOff ? '' : 'main-menu__magnit-icon-anim'}`}
        />
        <div className={'main-menu__content'}>
          <img
            src="/img/bell.png"
            className={`main-menu__bell ${animationOff ? '' : 'main-menu__bell-anim'}`}
            alt=""
          />
          {mainButtons.map((button, i) => (
            <div
              key={`menu-button-${i}`}
              className={`main-menu__button ${animationOff ? '' : 'main-menu__button-anim'}`}
              onClick={button.onClick}
              style={{ animationDelay: `${3 + i * 0.3}s` }}
            >
              <div className={`main-menu__icon ${button.icon} `} />
              <div className={`main-menu__text`}>{button.title}</div>
            </div>
          ))}
        </div>
        <a href="https://www.magnit.ru/" className={animationOff ? '' : 'advertisement'}>
          Реклама www.magnit.ru
        </a>
      </div>
      <div className={`main-menu__bottom ${animationOff ? '' : 'main-menu__bottom-anim'}`}>
        {bottomButtons.map((button, i) => (
          <div key={`menu-button-${i}`} className={`main-menu__bottom-el`}>
            <div
              className={`main-menu__bottom-button ${
                animationOff ? '' : 'main-menu__bottom-button-anim'
              }`}
              onClick={button.onClick}
              style={{ animationDelay: `${5.4 + i * 0.4}s` }}
            >
              <div className={`main-menu__bottom-icon ${button.icon} `} />
            </div>
            <div
              className={`main-menu__bottom-text ${
                animationOff ? '' : 'main-menu__bottom-text-anim'
              }`}
            >
              {button.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
