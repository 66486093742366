import React, { FC, ReactNode, useEffect } from 'react';

import './styles.css';

interface CustomModalProps {
  children: ReactNode;
}

export const CustomModal: FC<CustomModalProps> = ({ children }) => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const body = document.querySelector('body');
      if (body) {
        body.style.overflow = 'hidden';
      }
    }
    return () => {
      const body = document.querySelector('body');
      if (body) {
        body.style.overflow = 'auto';
      }
    };
  }, []);

  return (
    <div className={'modal'}>
      <div className={'modal__content'}>{children}</div>
    </div>
  );
};
