import { FC, useCallback, useEffect, useState } from 'react';
import { WrapperContent } from '../../components/WrapperContent';
import { Shop } from './Shop';
import { useNavigate } from 'react-router-dom';
import { Input } from '../../components/Input';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { fetchShops, fetchShopsByStreetName } from '../../store/shops/actions';
import { Wrapper } from '../../components/Wrapper';
import { debounce } from '../../utils';
import { Loader } from '../../components/Loader';

import './styles.css';

export const StoreSelection: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { shops, isLoadingShops } = useAppSelector(state => state.shops);

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (!searchValue) {
      dispatch(fetchShops());
    }
  }, []);

  const fetchShopsByStreet = debounce((street: string) => {
    dispatch(fetchShopsByStreetName(street));
  }, 700);

  const fetchShopsDefault = debounce(() => {
    dispatch(fetchShops());
  }, 700);

  const handleClose = () => {
    navigate('/catalog');
  };

  const handleChangeInput = useCallback((value: string) => {
    setSearchValue(value);
    if (value && value.length > 2) {
      fetchShopsByStreet(value);
    } else {
      fetchShopsDefault();
    }
  }, []);

  return (
    <Wrapper>
      <WrapperContent>
        <div className="choose-shop__head">
          <div className="choose-shop__head__title">Выберите магазин</div>
          <div className="choose-shop__head__close-icon" onClick={handleClose} />
        </div>
        <div className="choose-shop__description">
          После выбора магазина мы покажем все актуальные акции этого магазина
        </div>

        <div className="choose-shop__search">
          <Input
            value={searchValue}
            onChange={handleChangeInput}
            placeholder={'Введите адрес магазина...'}
          />
        </div>
        {isLoadingShops || !shops?.length ? (
          <div className="shop-loader">
            {!shops?.length ? (
              `Магазины ${searchValue ? 'по вашему запросу' : 'поблизости'} не найдены`
            ) : (
              <>
                {`Ищем магазины ${searchValue ? 'по вашему запросу' : 'поблизости'}...`}
                <Loader />
              </>
            )}
          </div>
        ) : (
          shops?.map(shop => <Shop key={`shop-list-${shop.apiId}-${shop.typeId}`} shop={shop} />)
        )}
      </WrapperContent>
    </Wrapper>
  );
};
