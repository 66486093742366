import { getCategoriesReq } from '../../API';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchCategories = createAsyncThunk(
  'categories/fetchAll',
  async (shopId: string, thunkAPI) => {
    try {
      return await getCategoriesReq(shopId);
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить магазины');
    }
  },
);
