import React, { FC, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { LocationProvider } from './components/LocationProvider';
import { BasketList, HomePage, MainMenu, StoreSelection } from './pages';
import { useTelegram } from './hooks/useTelegram';
import { ShopTypes } from './models/IShop';
import { getInitialData } from './utils';
import { useSelectedShops } from './hooks/useSelectedShops';
import { useAppDispatch } from './hooks/redux';
import { setCurrentShop } from './store/shops';
import { Terms } from './pages/Terms';

import './assets/fonts/stylesheet.css';
import './styles/variables.css';
import './styles/colors.css';
import './App.css';

const App: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { onExpand, disableClose } = useTelegram();
  const { selectedShopsFromLocal, currentShopFromLocal } = useSelectedShops();

  useEffect(() => {
    if (pathname.includes('terms')) {
      navigate('/terms');
    } else if (pathname.includes('shops')) {
      navigate('/shops');
    } else {
      navigate('/');
    }

    if (selectedShopsFromLocal && currentShopFromLocal) {
      (async () => {
        dispatch(setCurrentShop(selectedShopsFromLocal[currentShopFromLocal]));

        for (const key in selectedShopsFromLocal) {
          const shop = selectedShopsFromLocal[Number(key) as ShopTypes];
          await getInitialData(shop, dispatch, false);
        }
      })();
    }

    onExpand();
    disableClose();
  }, []);

  return (
    <>
      <LocationProvider>
        <Routes>
          <Route path="/" element={<MainMenu />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/shops" element={<StoreSelection />} />
          <Route path="/catalog" element={<HomePage />} />
          <Route path="/basket" element={<BasketList />} />
        </Routes>
      </LocationProvider>
    </>
  );
};

export default App;
