import { FC } from 'react';

import './styles.css';
import { useNavigate } from 'react-router-dom';
import { useTelegram } from '../../hooks/useTelegram';

export const Terms: FC = () => {
  const navigate = useNavigate();
  const { onOpenLink } = useTelegram();

  const list = [
    {
      title: 'На сумму от 500 ₽ = 1 попытка',
      description: 'в магазинах Магнит Косметик, Магнит у дома, Магнит Аптека',
    },
    {
      title: 'На сумму от 1000 ₽ = 1 попытка',
      description: 'в магазинах Магнит Семейный, Магнит Экстра, Магнит Опт',
    },
    { title: 'Товары-спонсоры' },
  ];

  return (
    <div className={'terms'}>
      <div className={'terms__wrapper'}>
        <div
          className={'terms__back'}
          onClick={() => {
            navigate('/');
          }}
        >
          ←
        </div>
        <div className={'terms__content'}>
          <div className={'terms__title'}>Условия акции</div>
          <div className={'terms__game-description'}>
            Покупайте в наших магазинах с картой из{' '}
            <div
              className={'link'}
              onClick={() =>
                onOpenLink(' https://redirect.appmetrica.yandex.com/serve/171989369295545187')
              }
            >
              приложения Магнит
            </div>{' '}
            и получайте получайте больше попыток в игре и шансов в розыгрыше ценных призов:
          </div>
          {list.map((el, i) => (
            <div className={'terms__item'} key={`list-${i}`}>
              <div className={'terms__ellipse'} />
              <div>
                {el.title ? <div className={'terms__item-title'}>{el.title}</div> : null}
                {el.description ? <div className={'terms__item-text'}>{el.description}</div> : null}
              </div>
            </div>
          ))}
          <div className={'terms__item'}>
            <div className={'terms__info-icon'} />
            <div className={'terms__item-text'}>
              В сумме не учитываются алкоголь, табачная и никотиносодержащая продукция, а также
              устройства для нее
            </div>
          </div>

          <div className={'terms__item'}>
            <div className={'terms__info-icon'} />
            <div className={'terms__item-text'}>Покупка, оплаченная бонусами, не учитывается.</div>
          </div>

          <div className={'terms__subtitle'}>
            Купили без карты из приложения Магнит? Купили в доставке?
          </div>

          <div className={'terms__item'}>
            <div className={'terms__selected-icon'} />
            <div className={'terms__item-text'}>
              Забыли отсканировать карту? Не страшно! Просто отправьте фото чека мне в ответном
              сообщении в течение 120 часов после покупки и участвуйте в розыгрыше ценных призов
            </div>
          </div>

          <div className={'terms__item'}>
            <div className={'terms__info-icon'} />
            <div className={'terms__item-text'}>
              Принимаются чеки из магазинов «Магнит», «Магнит Экстра», «Магнит Семейный», созданные
              через сервисы «Магнит.Доставка», «Деливери Клаб», «Сбермаркет», «Яндекс.Еда»
            </div>
          </div>

          <div className={'terms__item'}>
            <div className={'terms__info-icon'} />
            <div className={'terms__item-text'}>
              Чеки из сервиса «Сбермаркет» для участия в розыгрыше должны быть на сумму не менее
              1000 рублей
            </div>
          </div>

          <div className={'terms__item no-mb'}>
            <div className={'terms__info-icon'} />
            <div className={'terms__item-text'}>
              Дополнительные попытки через чат-бот можно получить только за 2 покупки в сутки из
              одного магазина
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
