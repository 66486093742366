import React, { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IShop } from '../../../models/IShop';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { generateWorkTime, getShopLogoSrc, resetShopData } from '../../../utils';
import { useSelectedShops } from '../../../hooks/useSelectedShops';
import { setCurrentShop } from '../../../store/shops';
import { CustomModal } from '../../../components/CustomModal';
import { ResetBasketModal } from '../ResetBasketModal';
import { clearBasket } from '../../../store/basket/actions';
import { bindShopReq } from '../../../API';

import './styles.css';

interface ShopProps {
  shop: IShop;
}

export const Shop: FC<ShopProps> = ({ shop }) => {
  const { basketProducts } = useAppSelector(state => state.basket);
  const { saveSelectedShopsToLocal, selectedShopsFromLocal } = useSelectedShops();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isResetBasketModalOpen, setIsResetBasketModalOpen] = useState<boolean>(false);

  const shopProducts = useMemo(() => basketProducts?.[shop.typeId] || [], [basketProducts, shop]);

  const handleCloseModal = () => setIsResetBasketModalOpen(false);

  const clearLastShopBasket = async () => {
    const lastShop = selectedShopsFromLocal?.[shop.typeId];

    if (lastShop) {
      await dispatch(clearBasket(lastShop));
    }
  };

  const handleSelectShop = async (needClear?: boolean) => {
    if (needClear) {
      await clearLastShopBasket();
    }
    saveSelectedShopsToLocal(shop);
    dispatch(setCurrentShop(shop));
    resetShopData(dispatch);
    await bindShopReq(shop.apiId);

    navigate('/catalog');
  };

  const handleClick = async () => {
    const lastShop = selectedShopsFromLocal?.[shop.typeId];

    if (shopProducts.length && lastShop && lastShop.apiId !== shop.apiId) {
      setIsResetBasketModalOpen(true);
      return;
    }

    await handleSelectShop();
  };

  const shopLogo = useMemo(() => getShopLogoSrc(shop.typeId), [shop.typeId, shop.apiId]);

  return (
    <>
      <div className="shop-item">
        <img className="shop-item__logo" src={shopLogo} alt="" />
        <div className={'shop-item__content'}>
          <div className="shop-item__address shop-item__text">{shop.address}</div>
          <div className="shop-item__bottom">
            <div className={'shop-item__clock-icon'} />
            <div className="shop-item__clock-text shop-item__text">
              {generateWorkTime(shop.workFrom, shop.workTo)}
            </div>
            <div className="shop-item__button shop-item__text" onClick={handleClick}>
              Выбрать
            </div>
          </div>
        </div>
      </div>
      {isResetBasketModalOpen ? (
        <CustomModal>
          <ResetBasketModal
            shopTypeId={shop.typeId}
            handleClose={handleCloseModal}
            handleAccept={() => handleSelectShop(true)}
          />
        </CustomModal>
      ) : null}
    </>
  );
};
