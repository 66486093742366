import { IWorkTime } from '../models/IWorkTime';
import { ISelectedShops, IShop, ShopTypes } from '../models/IShop';
import { getProductsType, SendBasketProductReqData, SendBasketReqData } from '../API';
import { fetchBasket } from '../store/basket/actions';
import { fetchCategories } from '../store/categories/actions';
import { fetchShopFilters } from '../store/filter/actions';
import { fetchProducts } from '../store/products/actions';
import { AppDispatch } from '../store/store';
import { IProduct } from '../models/IProduct';
import { Totals } from '../hooks/useTotals';
import { resetProducts } from '../store/products';
import { resetFilter } from '../store/filter';
import { resetCategory } from '../store/categories';

export const getShopLogoSrc = (shopType?: number) => {
  if (shopType === 4) {
    return 'img/labelGreen.svg';
  }
  if (shopType === 3) {
    return 'img/labelPink.svg';
  }
  if (shopType === 1) {
    return 'img/labelRed.svg';
  }

  return 'img/labelYellow.svg';
};

export const generateWorkTime = (from: IWorkTime, to: IWorkTime) => {
  const oursFrom = `${from?.hour < 10 ? '0' : ''}${from?.hour}:00`;
  const oursTo = to?.hour ? `${to?.hour < 10 ? '0' : ''}${to?.hour}:00` : '23:59';

  return `${oursFrom} - ${oursTo}`;
};

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const resetShopData = (dispatch: AppDispatch) => {
  dispatch(resetProducts());
  dispatch(resetFilter());
  dispatch(resetCategory());
};

export const getInitialData = (shop: IShop, dispatch: AppDispatch, allReqs: boolean) =>
  new Promise<void>(async resolve => {
    const params: getProductsType = {
      shopId: shop.apiId,
    };

    await dispatch(fetchBasket(shop));

    if (allReqs) {
      await dispatch(fetchCategories(shop.apiId));
      await dispatch(fetchShopFilters(shop.apiId));
      await dispatch(fetchProducts(params));
    }

    resolve();
  });

export const debounce = (fn: (...args: any[]) => void, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const numberWithSpaces = (number: string): string => {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};

export const generateSendBasketReqData = (
  shops: ISelectedShops,
  products: { [key in ShopTypes]: IProduct[] },
  totals: Totals,
) => {
  const res: SendBasketReqData[] = [];

  for (const key in shops) {
    const shopTypeId = Number(key) as ShopTypes;
    const curShop = shops?.[shopTypeId];
    const curProducts = products?.[shopTypeId];
    const curEconomy = totals?.[shopTypeId];

    if (curShop && curProducts?.length) {
      const usersBasketDTOs: SendBasketProductReqData[] = curProducts.map(p => ({
        goodCode: p.id,
        goodName: p.name,
        amount: p.amount,
        oldPrice: p.oldPrice || 0,
        newPrice: p.price,
      }));

      res.push({
        shopAdress: curShop.address,
        shopFormat: getShopTypeName(curShop.typeId),
        economy: curEconomy?.totalShopBenefit ? Number(curEconomy?.totalShopBenefit) : 0,
        usersBasketDTOs,
      });
    }
  }

  return res;
};

export const getShopTypeName = (shopTypeId: number): string => {
  if (shopTypeId === 4) {
    return 'Магнит Аптека';
  }

  if (shopTypeId === 3) {
    return 'Магнит Косметик';
  }

  if (shopTypeId === 1) {
    return 'Магнит у дома';
  }

  return 'Магнит Экстра';
};

export const randomInteger = (min: number, max: number) =>
  Math.round(min - 0.5 + Math.random() * (max - min + 1));
