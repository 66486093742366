import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/redux';
import { getShopLogoSrc, getShopTypeName } from '../../../../utils';

import './styles.css';

export const Shop: FC = () => {
  const navigate = useNavigate();
  const { currentShop } = useAppSelector(state => state.shops);

  const handleClick = () => {
    navigate('/shops');
  };

  const shopLogo = useMemo(() => getShopLogoSrc(currentShop.typeId), [currentShop]);

  return (
    <div className="shop-selected">
      <img className="shop-selected__logo" src={shopLogo} alt="" />
      <div className="shop-selected__title">
        {`${getShopTypeName(currentShop.typeId)} `}
        <b>{currentShop.name}</b>
      </div>
      <div className="shop-selected__icon" onClick={handleClick} />
    </div>
  );
};
