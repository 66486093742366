import { ISelectedShops, IShop, ShopTypes } from '../models/IShop';

export const useSelectedShops = () => {
  const currentShopInString = localStorage.getItem('currentShopId');
  const currentShopFromLocal = currentShopInString
    ? (Number(currentShopInString) as ShopTypes)
    : undefined;

  const selectedShopsInString = localStorage.getItem('selectedShops');
  const selectedShopsFromLocal = selectedShopsInString
    ? (JSON.parse(selectedShopsInString) as ISelectedShops)
    : undefined;

  const saveSelectedShopsToLocal = (shop: IShop) => {
    let params;
    if (selectedShopsFromLocal) {
      params = { ...selectedShopsFromLocal, [shop.typeId]: shop };
    } else {
      params = { [shop.typeId]: shop };
    }

    localStorage.setItem('selectedShops', JSON.stringify(params));
    localStorage.setItem('currentShopId', shop.typeId.toString());
  };

  return {
    selectedShopsFromLocal,
    currentShopFromLocal,
    saveSelectedShopsToLocal,
  };
};
