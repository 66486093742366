import React, { FC, useCallback, useState } from 'react';
import { FilterState, Interval, SortType, SortVariants } from '../../store/filter/types';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { initialState, setFilters } from '../../store/filter';
import { fetchProducts } from '../../store/products/actions';
import { getProductsType } from '../../API';
import { MultiRangeSliderPrice, MultiRangeSliderSale } from './components';

import './styles.css';

interface FilterProps {
  onCloseModal: () => void;
}

export const Filter: FC<FilterProps> = ({ onCloseModal }) => {
  const dispatch = useAppDispatch();
  const { filters, categories, shops } = useAppSelector(state => state);

  const [tempFilters, setTempFilter] = useState<FilterState>(filters);

  const handleChangeSaleFilter = (tempSale: Interval) => {
    setTempFilter(prev => ({ ...prev, tempSale }));
  };

  const handleChangePriceFilter = (tempPrice: Interval) => {
    setTempFilter(prev => ({ ...prev, tempPrice }));
  };

  const handleChangeSortType = (type: SortVariants) => {
    const sort: SortType = {
      type,
      order: tempFilters.sort.order === 'asc' ? 'desc' : 'asc',
    };

    setTempFilter(prev => ({ ...prev, sort }));
  };

  const handleSubmit = useCallback(() => {
    const params: getProductsType = {
      price: tempFilters.tempPrice,
      sale: tempFilters.tempSale,
      sort: tempFilters.sort,
      searchString: filters.searchString,
      groupIds: categories.selectedCategories.map(el => el.id).join(',') || '',
      shopId: shops?.currentShop?.apiId,
    };

    dispatch(fetchProducts(params));
    dispatch(setFilters(tempFilters));

    onCloseModal();
  }, [tempFilters, categories, shops]);

  const handleReset = useCallback(() => {
    handleChangeSaleFilter(filters.initSale);
    handleChangePriceFilter(filters.initPrice);
    setTempFilter(prev => ({ ...prev, sort: initialState.sort }));
  }, [initialState, categories, shops]);

  const sortTitles = {
    price: 'По цене',
    discount: 'По размеру скидки',
  };

  return (
    <div className="filter__wrapper">
      <div className={'filter__head'}>
        Настроить показ товаров
        <div className={'filter__close-icon'} onClick={onCloseModal} />
      </div>

      <div className={'filter__gray-line'} />

      <div className={'filter__element'}>
        <div className={'filter__element-title'}>Размер скидки</div>
        <MultiRangeSliderSale
          min={tempFilters.tempSale.min}
          max={tempFilters.tempSale.max}
          onChange={handleChangeSaleFilter}
        />
      </div>

      <div className={'filter__gray-line'} />

      <div className={'filter__element'}>
        <div className={'filter__element-title'}>Цена</div>
        <MultiRangeSliderPrice
          min={tempFilters.tempPrice.min}
          max={tempFilters.tempPrice.max}
          onChange={handleChangePriceFilter}
        />
      </div>

      <div className={'filter__gray-line'} />

      <div className={'filter__element'}>
        <div className={'filter__element-title'}>Упорядочить</div>

        <div className="filter__element-sort">
          {(['price', 'discount'] as (keyof typeof sortTitles)[]).map(type => (
            <div
              className={'filter__element-sort-item'}
              key={type}
              onClick={() => handleChangeSortType(type)}
            >
              <div
                className={`filter__element-sort-icon${
                  tempFilters.sort.order === 'desc' && tempFilters.sort.type === type
                    ? ' filter__rotate'
                    : ''
                }`}
              />
              <div
                className={`filter__element-sort-text${
                  tempFilters.sort.type === type ? ' filter__bold' : ''
                }`}
              >
                {sortTitles[type]}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={'filter__submit'}>
        <div className={'filter__submit-button'} onClick={handleSubmit}>
          ПРИМЕНИТЬ
        </div>
        <div className={'filter__trash-icon'} onClick={handleReset} />
      </div>
    </div>
  );
};
