import { getShopsByStreetReq, getShopsReq } from '../../API';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchShops = createAsyncThunk('shops/fetchAll', async (_, thunkAPI) => {
  try {
    return await getShopsReq();
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось загрузить магазины');
  }
});

export const fetchShopsByStreetName = createAsyncThunk(
  'shops/fetchShopsByStreetName',
  async (street: string, thunkAPI) => {
    try {
      return await getShopsByStreetReq(street);
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить магазины по названию улицы');
    }
  },
);
