import React, { FC } from 'react';

import './styles.css';

interface InputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

export const Input: FC<InputProps> = ({ value, onChange, placeholder }) => {
  return (
    <div className={'input__wrapper'}>
      <input
        className="input"
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder || ''}
      ></input>
      {value ? <div className={'input__close-icon'} onClick={() => onChange('')} /> : null}
    </div>
  );
};
