import React, { useEffect } from 'react';
import { WrapperContent } from '../../components/WrapperContent';
import { Categories, FilterByCategory, Shop, Products } from './components';
import { Wrapper } from '../../components/Wrapper';
import { getInitialData } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';

export const HomePage = () => {
  const dispatch = useAppDispatch();
  const { currentShop } = useAppSelector(state => state.shops);

  useEffect(() => {
    (async () => {
      if (currentShop) {
        await getInitialData(currentShop, dispatch, true);
      }
    })();
  }, [currentShop]);

  return (
    <Wrapper>
      <WrapperContent>
        <Shop />
        <Categories />
        <FilterByCategory />
        <Products />
      </WrapperContent>
    </Wrapper>
  );
};
