import React, { FC, ReactNode } from 'react';

import './styles.css';

interface WrapperContentProps {
  children: ReactNode;
  underWrapper?: ReactNode;
  noPadding?: boolean;
}

export const WrapperContent: FC<WrapperContentProps> = ({ children, underWrapper, noPadding }) => {
  return (
    <div className={'wrapperMain'}>
      <div className={`wrapperContent${noPadding ? ' no-padding' : ''}`}>{children}</div>
      {underWrapper ? underWrapper : null}
    </div>
  );
};
