import { CategoryProps } from './types';
import { ICategory } from '../../models/ICategory';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCategories } from './actions';

const initialState: CategoryProps = {
  selectedCategories: [],
  categories: [],
  isLoadingCategories: true,
  error: '',
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setSelectedCategory: (state, action: PayloadAction<ICategory[]>) => {
      state.selectedCategories = action.payload;
    },
    resetCategory: state => {
      state.selectedCategories = [];
      state.categories = [];
    },
  },
  extraReducers: {
    [fetchCategories.pending.type]: state => {
      state.isLoadingCategories = true;
    },
    [fetchCategories.fulfilled.type]: (state, action: PayloadAction<ICategory[]>) => {
      state.isLoadingCategories = false;
      state.error = '';
      state.categories = action.payload.sort((a: ICategory, b: ICategory) => a?.id - b?.id);
    },
    [fetchCategories.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingCategories = false;
      state.error = action.payload;
    },
  },
});

export const { setSelectedCategory, resetCategory } = categoriesSlice.actions;

const reducer = categoriesSlice.reducer;
export default reducer;
