import React, { FC, useMemo } from 'react';
import { generateWorkTime, getShopLogoSrc } from '../../../../utils';
import { IShop } from '../../../../models/IShop';
import { useAppDispatch } from '../../../../hooks/redux';
import { clearBasket } from '../../../../store/basket/actions';

import './styles.css';

interface HeaderBasketShopProps {
  shop: IShop;
}

export const HeaderBasketShop: FC<HeaderBasketShopProps> = ({ shop }) => {
  const dispatch = useAppDispatch();

  const handleClearBasket = () => {
    dispatch(clearBasket(shop));
  };

  const shopLogo = useMemo(() => getShopLogoSrc(shop?.typeId), [shop]);

  return (
    <div className="header-basket">
      <img className={'header-basket__shop-logo'} src={shopLogo} alt="" />
      <div className={'header-basket__content'}>
        <div className={'header-basket__shop-name'}>{shop.name}</div>
        <div className={'header-basket__work-time'}>
          <div className={'header-basket__clock-icon'} />
          {generateWorkTime(shop?.workFrom, shop?.workTo)}
        </div>
      </div>
      <div className={'header-basket__remove-wrapper'} onClick={handleClearBasket}>
        <div className={'header-basket__close-icon'} />
      </div>
    </div>
  );
};
