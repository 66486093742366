import React, { FC, ReactNode } from 'react';
import { Header } from '../Header';
import { MoveTopButton } from '../MoveTopButton';

import './styles.css';

interface WrapperProps {
  children: ReactNode;
}

export const Wrapper: FC<WrapperProps> = ({ children }) => {
  return (
    <div className="wrapper">
      <Header />
      {children}
      <MoveTopButton />
    </div>
  );
};
