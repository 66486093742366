import { FC, useMemo } from 'react';
import { ShopTypes } from '../../../../models/IShop';
import { useSelectedShops } from '../../../../hooks/useSelectedShops';
import { useAppSelector } from '../../../../hooks/redux';
import { HeaderBasketShop } from '../HeaderBasketShop';
import Product from '../../../../components/Product';
import { useTotals } from '../../../../hooks/useTotals';

import './styles.css';
import { numberWithSpaces } from '../../../../utils';

interface BasketShopProps {
  shopType: ShopTypes;
}

export const BasketShop: FC<BasketShopProps> = ({ shopType }) => {
  const { totals } = useTotals();
  const { selectedShopsFromLocal } = useSelectedShops();
  const { basketProducts } = useAppSelector(state => state.basket);

  const currentShop = useMemo(
    () => selectedShopsFromLocal?.[shopType] || undefined,
    [selectedShopsFromLocal, shopType],
  );
  const currentTotals = useMemo(() => totals?.[shopType] || undefined, [totals, shopType]);

  const products = useMemo(() => basketProducts?.[shopType] || [], [basketProducts, shopType]);

  return currentShop && products.length && currentTotals ? (
    <>
      <HeaderBasketShop shop={currentShop} />

      <div className="basket__products-list">
        {products?.map((product, i) => (
          <Product product={product} key={`basket-product-${i}-${product.id}`} shop={currentShop} />
        ))}
      </div>
      <div className={'basket__shop-totals'}>
        <div className={'basket__line'}>
          <p>Итого по магазину:</p>
          <p>{numberWithSpaces(currentTotals.totalShopPrice)}₽</p>
        </div>
        <div className={'basket__line basket__red'}>
          <p>Ваша выгода:</p>
          <p>{numberWithSpaces(currentTotals.totalShopBenefit)}₽</p>
        </div>
      </div>
    </>
  ) : null;
};
