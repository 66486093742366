export const isDevApi = process.env.REACT_APP_BUILD === 'development';

export const BASE_URL = 'https://digitalcatalog-magnit.bots.winsolutions.ru/api';

export const BASE_URL_SEND_FIRST = 'https://magnitbot.bots.winsolutions.ru/api';

export const BASE_URL_SEND_SECOND = 'https://magnitbot.bots.winsolutions.ru/api';

// export const BASE_URL = isDevApi
//   ? 'https://digitalcatalog-dev.bots.winsolutions.ru/api'
//   : 'https://catalog.mgtprod2.winsolutions.ru/api';
//
// export const BASE_URL_SEND_FIRST = isDevApi
//   ? 'https://promobot.bots.winsolutions.ru/api'
//   : `https://service.mgtprod1.winsolutions.ru/api`;
//
// export const BASE_URL_SEND_SECOND = isDevApi
//   ? 'https://promobot.bots.winsolutions.ru/api'
//   : `https://service.mgtprod2.winsolutions.ru/api`;
