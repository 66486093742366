import { FC } from 'react';

import './styles.css';

interface LoaderProps {
  width?: string;
}

export const Loader: FC<LoaderProps> = ({ width = '40px' }) => {
  return (
    <div className="loader" style={{ width }}>
      <svg className="circular" viewBox="25 25 50 50">
        <circle
          className="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
};
