import { useCallback, FC, useMemo } from 'react';
import { Category } from '../Category';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { ICategory } from '../../../../models/ICategory';
import { setSelectedCategory } from '../../../../store/categories';
import { getProductsType } from '../../../../API';
import { fetchProducts } from '../../../../store/products/actions';
import { Loader } from '../../../../components/Loader';

import './styles.css';

export const Categories: FC = () => {
  const dispatch = useAppDispatch();
  const {
    filters,
    categories: { categories, selectedCategories, isLoadingCategories },
    shops: { currentShop },
  } = useAppSelector(state => state);

  const handleClick = useCallback(
    (category?: ICategory, isActive?: boolean) => {
      let groupIds = '';
      if (!category) {
        dispatch(setSelectedCategory([]));
      } else {
        if (!isActive) {
          const newList = [...selectedCategories, category];
          groupIds = newList.map(el => el.id).join(',');
          dispatch(setSelectedCategory(newList));
        } else {
          const newList = selectedCategories.filter(el => el.id !== category.id);
          groupIds = newList.map(el => el.id).join(',');
          dispatch(setSelectedCategory(newList));
        }
      }

      const params: getProductsType = {
        price: filters.tempPrice,
        sale: filters.tempSale,
        sort: filters.sort,
        searchString: filters.searchString,
        groupIds,
        shopId: currentShop?.apiId,
      };

      dispatch(fetchProducts(params));
    },
    [selectedCategories, categories, filters, currentShop],
  );

  const renderData = useMemo(() => {
    if (categories?.length < 3) {
      return {
        left: categories,
        right: [],
      };
    }

    const middleIndex = Math.trunc(categories?.length / 2);
    return {
      left: categories?.slice(0, middleIndex),
      right: categories?.slice(middleIndex),
    };
  }, [categories]);

  const renderList = useCallback(
    (list: ICategory[]) =>
      list.length
        ? list.map(category => {
            if (!category) {
              return null;
            }

            const isActive = selectedCategories?.find(el => el?.id === category?.id);

            return (
              <Category
                active={!!isActive}
                name={category?.name}
                key={`category-${category?.id}`}
                onClick={() => handleClick(category, !!isActive)}
              />
            );
          })
        : null,
    [selectedCategories, handleClick],
  );

  return (
    <div className="categories">
      <div className="categories__title">Категории</div>
      {isLoadingCategories ? (
        <div className="categories-loader">
          <Loader />
        </div>
      ) : (
        <div className="categories__scroll">
          <div className={'categories__block'}>
            <Category
              name="Все товары"
              active={!selectedCategories.length}
              onClick={() => handleClick()}
            />
            {renderList(renderData?.left)}
          </div>
          <div className={'categories__block'}>{renderList(renderData?.right)}</div>
        </div>
      )}
    </div>
  );
};
