import { getShopFiltersReq } from '../../API';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchShopFilters = createAsyncThunk(
  'filter/fetchAll',
  async (shopId: string, thunkAPI) => {
    try {
      return await getShopFiltersReq(shopId);
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить фильтры магазина');
    }
  },
);
