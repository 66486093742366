import React, { FC } from 'react';

import './styles.css';

interface CategoryProps {
  name: string;
  active: boolean;
  onClick: () => void;
}

export const Category: FC<CategoryProps> = ({ name, active, onClick }) => {
  return (
    <button onClick={onClick} type="button" className={`category ${active && 'active'}`}>
      <span className="category__title">{name}</span>
    </button>
  );
};
